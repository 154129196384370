import axios from 'axios';
import {
    FRIEND_GET_SUCCESS,
    MESSAGE_GET_SUCCESS,
    MESSAGE_SEND_SUCCESS,
    THEME_GET_SUCCESS,
    THEME_SET_SUCCESS,
    UPLOAD_AUDIO_FAILURE,
    UPLOAD_AUDIO_SUCCESS
} from "../types/messengerType";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;






export const getFriends = () => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    
    try {
        const response = await axios.get(`${API_BASE_URL}/api/messenger/get-friends`, withAuthHeaderConfig);
        dispatch({
            type: FRIEND_GET_SUCCESS,
            payload: {
                friends: response.data.friends
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};


export const sendInvitation = (email) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/send-invitation`, {email}, withAuthHeaderConfig);
        // Check the response status and display appropriate alert
        if (response.status === 200) {
            dispatch({type: "ALERT_SUCCESS", payload: response.data.success});
        } else {
            dispatch({type: "ALERT_ERROR", payload: response.data.error});
        }
    } catch (error) {
        // If there's an error, display an alert with the error message
        dispatch({type: "ALERT_ERROR", payload: error.response.data.error});
    }
};


export const getUsers = (friendIds, myId) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    
    try {
        const response = await axios.get(`${API_BASE_URL}/api/messenger/get-all-users`, {
            params: {
                friendIds: friendIds,
                myId: myId
            },
            ...withAuthHeaderConfig
        });
        dispatch({
            type: 'GET_USERS_SUCCESS',
            payload: {
                users: response.data.users
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const addFriend = (friend, myId) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    
    console.log('friend', friend);
    const friendId = friend.fndInfo._id;
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/add-friend`, {friendId, myId}, withAuthHeaderConfig);
        dispatch({
            type: 'ADD_FRIEND_SUCCESS',
            payload: {
                friend: friend
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const updateProfile = (data) => async (dispatch) => {
    const forFormDataConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/update-profile`, data, forFormDataConfig);
        dispatch({
            type: 'PROFILE_UPDATE_SUCCESS',
            payload: {
                user: response.data.user,
                token: response.data.token
            }
        });
    } catch (error) {
        console.error(error);
    }
};

export const generateKeyFiles = (userId) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    
    try {
        const response = await axios.post(`${API_BASE_URL}/api/keys/generatekeys`, {userId}, withAuthHeaderConfig);
        dispatch({
            type: 'KEY_GENERATE_SUCCESS',
            payload: {
                publicKey: response.data.public_key
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const decryptData = (encryptedData, userId, type) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    let messageText;

    try {
        // Extraire et stocker le texte du message s'il existe
        if (encryptedData && encryptedData.message && encryptedData.message.text !== undefined) {
            messageText = encryptedData.message.text;
            delete encryptedData.message.text;
            console.log('messageText', messageText);
        }

        console.log('before axios', encryptedData);
        const response = await axios.post(`${API_BASE_URL}/api/keys/decrypt`, {encrypted: encryptedData, userId}, withAuthHeaderConfig);

        if (response.data && response.data.decrypted && response.data.decrypted.message) {
            // Traiter le texte décrypté
            if (response.data.decrypted.message.text !== undefined) {
                const valuesArray = Object.values(response.data.decrypted.message.text);
                const decryptedText = valuesArray.join('"');
                response.data.decrypted.message.text = decryptedText;
            }
        }

        // Réintégrer le texte du message s'il existe
        if (messageText !== undefined) {
            response.data.decrypted.message.text = messageText;
        }

        dispatch({
            type: 'DECRYPT_SUCCESS',
            payload: {
                decrypted: response.data.decrypted,
                type: type
            }
        });
    } catch (error) {
        console.error(error);
    }
};
