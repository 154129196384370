import React, { useEffect,useState } from 'react';
import { addFriend } from '../store/actions/friendAction';
const AddChoice = ({ myInfo, addUser, dispatch, setHideUsers, setCurrentFriend, setAddUser ,setShowAddFriendIcon,socket}) => {
const [addOrNot, setAddOrNot] = useState(false);
    useEffect(() => {
        if (addOrNot) {
        socket.current.emit('add-friend', { userId: myInfo.id, friendId: addUser.fndInfo._id });
        setAddUser('');
        setHideUsers(true);
        setCurrentFriend(addUser.fndInfo);
        setShowAddFriendIcon(false);
        }
        return () => {
            if (addOrNot) {
                socket.current.off('add-friend');
            }
        }
    }, [addOrNot]);
    const handleAdd = () => {
        dispatch(addFriend(addUser, myInfo.id));
        console.log(addUser);
        setAddOrNot(true);
    }

    const handleCancel = () => {
        setAddUser('');
    }

    return (
        <div className="AddChoice">
          <div className='add-choice-container'>
            <div className='top'>
                <h2>Add Friend</h2>
                <p>{`Do you want to add ${addUser.fndInfo.userName} as a friend?`}</p>
            </div>
            <div className='buttons'>
                <button className='cancel-button' onClick={handleCancel}>Cancel</button>
                <button className='add-button' onClick={handleAdd}>Add</button>
            </div>
        </div>
        </div>
    );
}

export default AddChoice;
