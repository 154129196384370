import axios from 'axios';
import {
    FRIEND_GET_SUCCESS,
    MESSAGE_GET_SUCCESS,
    MESSAGE_SEND_SUCCESS,
    THEME_GET_SUCCESS,
    THEME_SET_SUCCESS,
    UPLOAD_AUDIO_FAILURE,
    UPLOAD_AUDIO_SUCCESS
} from "../types/messengerType";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;





export const VideoMessageSend = (data) => async (dispatch) => {
    const forFormDataConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/video-message-send`, data, forFormDataConfig);
        dispatch({
            type: MESSAGE_SEND_SUCCESS,
            payload: {message: response.data.message}
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const DocumentMessageSend = (data) => async (dispatch) => {
    const forFormDataConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/document-message-send`, data, forFormDataConfig);
        dispatch({
            type: MESSAGE_SEND_SUCCESS,
            payload: {
                message: response.data.message
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const uploadAudio = (formData) => async (dispatch) => {
    const forFormDataConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/upload`, formData, forFormDataConfig);
        console.log('response', response);
        dispatch({
            type: 'UPLOAD_AUDIO_SUCCESS', payload: {
                message: response.data.message
            }
        });
    } catch (error) {
        dispatch({type: 'UPLOAD_AUDIO_FAIL', payload: error.message});
    }
};

export const getUnseenMessageNumber = (friendId) => async (dispatch) => {
    try {
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
};
console.log(friendId);
        const response = await axios.get(`${API_BASE_URL}/api/messenger/get-unseen-message-number/${friendId}`, config);
        return response.data.unseenMessageNumber;
    } catch (error) {
        console.error(error.response.data);
    }
};

export const setLastSeen = (userId) => async (dispatch) => {
    const friendId = userId;
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/set-last-seen`, {id: friendId}, withAuthHeaderConfig);
        dispatch({
            type: 'USER_DISCONNECTED',
            payload: {
                friendId: friendId
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const updateMsg = (msgId, newMsg) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/update-message`, {msgId, newMsg}, withAuthHeaderConfig);
        dispatch({
            type: 'MESSAGE_UPDATE_SUCCESS',
            payload: {
                msgId: msgId,
                newMsg: newMsg
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const deleteMessage = (msg) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/delete-message`, {_id: msg._id}, withAuthHeaderConfig);
        dispatch({
            type: 'MESSAGE_DELETE_SUCCESS',
            payload: {
                msg: msg
            }
        });
    } catch (error) {
        console.error(error);
    }
};

export const clearAlert = () => async (dispatch) => {
    dispatch({
        type: 'ALERT_CLEAR'
    });
};


export const sendAudioMessage = (data) => async (dispatch) => {
    const forFormDataConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    console.log("sendAudioMessage called with data:", data);
    if (data.get('audio')) {
        const formData = new FormData();
        formData.append('audio', data.get('audio'));
        formData.append('senderName', data.get('senderName'));
        formData.append('receiverId', data.get('receiverId'));

        // Log the FormData content
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        try {
            console.log("Sending audio message to server...");
            const response = await axios.post(`${API_BASE_URL}/api/messenger/send-audio`, formData, forFormDataConfig);
            console.log("Server response:", response.data);
            dispatch({
                type: MESSAGE_SEND_SUCCESS,
                payload: {
                    message: response.data.message,
                    audio: response.data.audio,
                    forwarded: data.get('forwarded')
                }
            });
        } catch (error) {
            console.error("Error sending audio message:", error);
            dispatch({
                type: UPLOAD_AUDIO_FAILURE,
                payload: error.response ? error.response.data : error.message
            });
        }
    } else {
        console.log("No audio data found");
    }
};

export const sendMessageToBackend = (data) => async (dispatch) => {
    console.log('data', data);
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/send-message`, data, withAuthHeaderConfig);
        console.log('response', response);
        dispatch({
            type: MESSAGE_SEND_SUCCESS,
            payload: {
                message: response.data.message,
                forwarded: data.forwarded
            }
        });
    } catch (error) {
        console.error(error.response.data);
        console.log('error', error);
    }
};

export const getMessage = (id) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    return async (dispatch) => {
        try {
            console.log(withAuthHeaderConfig);
            const response = await axios.get(`${API_BASE_URL}/api/messenger/get-message/${id}`, withAuthHeaderConfig);
            dispatch({
                type: MESSAGE_GET_SUCCESS,
                payload: {
                    message: response.data.message
                }
            });
        } catch (error) {
            console.log(error.response.data);
        }
    };
};

export const AudioMessageSend = (data) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/audio-message-send`, data, withAuthHeaderConfig);
        dispatch({
            type: MESSAGE_SEND_SUCCESS,
            payload: {
                message: response.data.message
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const ImageMessageSend = (data) => async (dispatch) => {
    const forFormDataConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/api/messenger/image-message-send`, data, forFormDataConfig);
        dispatch({
            type: MESSAGE_SEND_SUCCESS,
            payload: {
                message: response.data.message
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const seenMessage = (msg) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        console.log('msg seen message', msg);
        const response = await axios.post(`${API_BASE_URL}/api/messenger/seen-message`, msg, withAuthHeaderConfig);
    } catch (error) {
        console.error(error.response.message);
    }
};

export const updateMessage = (msg) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        console.log('msg update message', msg);
        const response = await axios.post(`${API_BASE_URL}/api/messenger/delivared-message`, msg, withAuthHeaderConfig);
    } catch (error) {
        console.error(error.response.message);
    }
};

export const getTheme = () => async (dispatch) => {
    const theme = localStorage.getItem('theme');
    dispatch({
        type: "THEME_GET_SUCCESS",
        payload: {
            theme: theme ? theme : 'white'
        }
    });
};

export const getAllMessages = (friendId) => async (dispatch) => {
    const withAuthHeaderConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    };
    try {
        const response = await axios.get(`${API_BASE_URL}/api/messenger/get-all-messages/${friendId}`, withAuthHeaderConfig);
        dispatch({
            type: MESSAGE_GET_SUCCESS,
            payload: {
                messages: response.data.message
            }
        });
    } catch (error) {
        console.error(error.response.data);
    }
};

export const themeSet = (theme) => async (dispatch) => {
    localStorage.setItem('theme', theme);
    dispatch({
        type: "THEME_SET_SUCCESS",
        payload: {
            theme: theme
        }
    });
};



