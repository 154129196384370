import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userRegister } from '../store/actions/userAction';
import { useAlert } from 'react-alert';
import { ERROR_CLEAR, SUCCESS_MESSAGE_CLEAR } from '../store/types/authType';

const Register = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, authenticate, error, successMessage } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    userName: '',
    email: '',
    image: '',
  });

  const [loadImage, setLoadImage] = useState('');

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const fileHandle = (e) => {
    if (e.target.files.length !== 0) {
        const file = e.target.files[0];
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];

        if (!validTypes.includes(file.type)) {
            alert.error("Only JPEG, PNG, and GIF files are allowed!");
            return; // Exit the function if the file type is not valid
        }

        const reader = new FileReader();
        reader.onload = () => {
            setLoadImage(reader.result);
        };
        reader.readAsDataURL(file);
        setState({
            ...state,
            [e.target.name]: file,
        });
    }
};

  const register = (e) => {
    e.preventDefault();
    const { userName, email, image } = state;
    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('email', email);
    formData.append('image', image);

    dispatch(userRegister(formData));
  };

  useEffect(() => {
    if (successMessage) {
      alert.success(successMessage);
      dispatch({ type: SUCCESS_MESSAGE_CLEAR });
      navigate('/messenger/login');
    }
    if (error) {
      error.map((err) => alert.error(err));
      dispatch({ type: ERROR_CLEAR });
    }
  }, [successMessage, error]);

  return (
    <div className="register">
      <div className="_card">
        <div className="card">
          <img className="logo" src={require('../imagelog/midadBlue.png')} alt="Midad Logo" />
          <div className="card-header">
            <h1>Register</h1>
          </div>
          <div className="card-body">
            <form onSubmit={register} encType="multipart/form-data">
              <div className="form-group">
                <label htmlFor="username">User Name</label>
                <input
                  type="text"
                  onChange={inputHandle}
                  name="userName"
                  value={state.userName}
                  className="form-control"
                  placeholder="User Name"
                  id="username"
                />
              </div>
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input type="email"
                  onChange={inputHandle}
                  name="email"
                  value={state.email}
                  className='form-control'
                  placeholder='Email'
                  id='email'
                />
              </div>
              <div className="form-group">
                <div className="file-image">
                  <div className="image">{loadImage ? <img src={loadImage} alt="Selected" /> : ''}</div>
                  <div className="file">
                    <label htmlFor="image">Select Image</label>
                    <input type="file" onChange={fileHandle} name="image" className="form-control" id="image" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input type="submit" value="Register" className="btn" />
              </div>
              <div className="form-group">
                <span>
                  <Link to="/messenger/login"> Login Your Account </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
