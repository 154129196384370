import React ,{useEffect, useState} from 'react';
import { FaSistrix, FaSignOutAlt } from 'react-icons/fa';
import ActiveFriend from './ActiveFriend';
import FriendList from './FriendList';

const LeftSide = ({
    activeUser,
    hideUsers,
    setHideUsers,
    myInfo,
    logout,
    search,
    friends,
    handleOnClickFriend,
    users,
    currentfriend,
    setAddUser,
    dispatch,
    handleHide2,
    hide,
    themeSet,
    greenandother,
    inCall,
    handleClickActiveFriend,
    showAddFriendIcon,
    setShowAddFriendIcon,
    showProfile,
    setShowProfile
}) => {
    const [isMobile, setIsMobile] = useState(false);

    // This function checks if the screen width is 767px or less
    const checkScreenWidth = () => {
      if (window.innerWidth <= 767) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  
    useEffect(() => {
      // Check screen size on initial load
      checkScreenWidth();
  
      // Add event listener to handle resizing
      window.addEventListener('resize', checkScreenWidth);
  
      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', checkScreenWidth);
      };
    }, []);

    useEffect(() => {
        console.log(showProfile);
    }, [showProfile]);
    const toggleGoBack = () => {
        setHideUsers(!hideUsers);
        setShowAddFriendIcon(!showAddFriendIcon);
    };
    const toggleAddFriend = () => {
        setHideUsers(!hideUsers);
        setShowAddFriendIcon(!showAddFriendIcon);
    };
    const handleProfile = () => {
        setShowProfile(!showProfile);
    };
    useEffect(() => {
        console.log(currentfriend);
    }
    , [currentfriend]);

    return (
        <>
        {!isMobile ?(<div className='left-side'>
            <div className='top'>
                <div className='icons'>
                    <div className='image-name' onClick={() => handleHide2('icon')}>
                            <img src={`./backend/images/${myInfo.image}`} alt='' />
                    </div>
                    <div className={hide ? 'theme_logout' : 'theme_logout show'}>
                        <div className='profile' onClick={() => handleProfile()}>
                            <i className='material-symbols-outlined'>Person</i>
                            <h3>Profile</h3>
                        </div>
                        <div className='dark-mode'>
                            <i className='material-symbols-outlined'>Dark_Mode</i>
                        <h3>Dark mode </h3>
                        {/* {themeSet !== 'dark' ?
                        <i className='material-symbols-outlined toOn' onClick={() => dispatch(themeSet('white'))}>Toggle_On</i>
                        :
                        <i className='material-symbols-outlined toOff' onClick={() => dispatch(themeSet('dark'))}>ToggleOff</i>
                        } */}
                        </div>
                        <div onClick={logout} className='logout'>
                            <i className='material-symbols-outlined'>Logout</i>
                            <h3>Logout</h3>
                        </div>
                    </div>
                </div>
                <div className='name'>
                         <h2>{myInfo.userName} </h2>
                         </div>

            </div>
            <div className='friend-search'>
                {
                    showAddFriendIcon &&
                    <div className='add-friend-icon' onClick={toggleGoBack}>
                        <i className='material-symbols-outlined'>Chevron_Left</i>
                    </div>
                }
                <div className='search'>
                    <button>
                        <FaSistrix />
                    </button>
                    <input onChange={search} type='text' placeholder='Search' className='form-control' />
                </div>
                {!showAddFriendIcon &&
                    <div className='add-friend' onClick={toggleAddFriend}>
                        <i className='material-symbols-outlined'>Add</i>
                    </div>
                }
            </div>

            <div className='active-friends'>
                {/* add a condition that the user has to be a friend */}
                {activeUser && hideUsers && activeUser.length > 0 ? activeUser.map((u) => <ActiveFriend key={u.userId} user={u} inCall={inCall} handleClickActiveFriend={handleClickActiveFriend} />) : ''}
            </div>

            <FriendList
                friends={friends}
                activeUser={activeUser}
                myInfo={myInfo}
                handleOnClickFriend={handleOnClickFriend}
                hideUsers={hideUsers} // Pass hideUsers prop to FriendList
                dispatch={dispatch} // Pass dispatch function to FriendList
                users={users} // Pass users array to FriendList
                currentfriend={currentfriend}
                setAddUser={setAddUser}
            />
        </div>
        ) : (
            !currentfriend && <div className='left-side'>
            <div className='top'>
                <div className='icons'>
                    <div className='image-name' onClick={() => handleHide2('icon')}>
                            <img src={`./backend/images/${myInfo.image}`} alt='' />
                    </div>
                    <div className={hide ? 'theme_logout' : 'theme_logout show'}>
                        <div className='profile' onClick={() => handleProfile()}>
                            <i className='material-symbols-outlined'>Person</i>
                            <h3>Profile</h3>
                        </div>
                        <div className='dark-mode'>
                            <i className='material-symbols-outlined'>Dark_Mode</i>
                        <h3>Dark mode </h3>
                        {/* {themeSet !== 'dark' ?
                        <i className='material-symbols-outlined toOn' onClick={() => dispatch(themeSet('white'))}>Toggle_On</i>
                        :
                        <i className='material-symbols-outlined toOff' onClick={() => dispatch(themeSet('dark'))}>ToggleOff</i>
                        } */}
                        </div>
                        <div onClick={logout} className='logout'>
                            <i className='material-symbols-outlined'>Logout</i>
                            <h3>Logout</h3>
                        </div>
                    </div>
                </div>
                <div className='name'>
                         <h2>{myInfo.userName} </h2>
                         </div>

            </div>
            <div className='friend-search'>
                {
                    showAddFriendIcon &&
                    <div className='add-friend-icon' onClick={toggleGoBack}>
                        <i className='material-symbols-outlined'>Chevron_Left</i>
                    </div>
                }
                <div className='search'>
                    <button>
                        <FaSistrix />
                    </button>
                    <input onChange={search} type='text' placeholder='Search' className='form-control' />
                </div>
                {!showAddFriendIcon &&
                    <div className='add-friend' onClick={toggleAddFriend}>
                        <i className='material-symbols-outlined'>Add</i>
                    </div>
                }
            </div>

            <div className='active-friends'>
                {/* add a condition that the user has to be a friend */}
                {activeUser && hideUsers && activeUser.length > 0 ? activeUser.map((u) => <ActiveFriend key={u.userId} user={u} inCall={inCall} handleClickActiveFriend={handleClickActiveFriend} />) : ''}
            </div>

            <FriendList
                friends={friends}
                activeUser={activeUser}
                myInfo={myInfo}
                handleOnClickFriend={handleOnClickFriend}
                hideUsers={hideUsers} // Pass hideUsers prop to FriendList
                dispatch={dispatch} // Pass dispatch function to FriendList
                users={users} // Pass users array to FriendList
                currentfriend={currentfriend}
                setAddUser={setAddUser}
            />
        </div>
        )
        }
        </>
    );
};

export default LeftSide;
